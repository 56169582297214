import { lazy } from 'react';
import { DefaultRoute } from './guard.jsx';

const Dashboard = lazy(() => import('../scenes/dashboard'));
const Invoices = lazy(() => import('../scenes/progress'));
const Contacts = lazy(() => import('../scenes/contacts'));
const Training = lazy(() => import('../scenes/training'));
const Form = lazy(() => import('../scenes/login'));
const Weight = lazy(() => import('../scenes/weight'));
const Sleep = lazy(() => import('../scenes/sleep'));
const FAQ = lazy(() => import('../scenes/feedback'));
const Calendar = lazy(() => import('../scenes/calendar'));
const Profile = lazy(() => import('../scenes/profile'));

export const routes = [
  {
    key: 'dashboard-route',
    title: 'Dashboard',
    path: '/',
    enabled: !!sessionStorage.getItem('jwt'),
    component: Dashboard,
  },
  {
    key: 'registration-route',
    title: 'Registration',
    path: '/registrieren',
    enabled: false, // !sessionStorage.getItem("jwt"),
    component: Form,
  },
  {
    key: 'kontakte-route',
    title: 'Kontakte',
    path: '/kontakte',
    enabled: false, // !!sessionStorage.getItem("jwt") && isCoach(),
    component: Contacts,
  },
  {
    key: 'fortschritte-route',
    title: 'Fortschritte',
    path: '/fortschritte',
    enabled: false, // !!sessionStorage.getItem("jwt") && isCoach(),
    component: Invoices,
  },
  {
    key: 'faq-route',
    title: 'FAQ',
    path: '/faq',
    enabled: !!sessionStorage.getItem('jwt'),
    component: FAQ,
  },
  {
    key: 'kalender-route',
    title: 'Kalender',
    path: '/kalender',
    enabled: !!sessionStorage.getItem('jwt'),
    component: Calendar,
  },
  {
    key: 'training-route',
    title: 'Training',
    path: '/training',
    enabled: !!sessionStorage.getItem('jwt'),
    component: Training,
  },
  {
    key: 'schlaf-route',
    title: 'Schlaf',
    path: '/schlaf',
    enabled: !!sessionStorage.getItem('jwt'),
    component: Sleep,
  },
  {
    key: 'gewicht-route',
    title: 'Gewicht',
    path: '/gewicht',
    enabled: !!sessionStorage.getItem('jwt'),
    component: Weight,
  },
  {
    key: 'Profil-route',
    title: 'Profil',
    path: '/profil',
    enabled: !!sessionStorage.getItem('jwt'),
    component: Profile,
  },
  {
    key: 'unmapped',
    title: 'Unmapped Page',
    path: '*',
    enabled: true,
    component: DefaultRoute,
  },
];
