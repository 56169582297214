import { isProduction } from './isProduction';

/**
 * This helper function returns the current domain of the API.
 * If the environment is production, the production Dokku URL will be returned.
 * Otherwise, the link localhost:8080 will be returned (Spring server default port).
 * @returns {string}
 */
export const getDomain = (): string => {
  const prodUrl = 'https://fijo.polat.family/api/v1';
  const devUrl = process.env.REACT_APP_SERVER_IP
    ? 'http://' + process.env.REACT_APP_SERVER_IP + ':8000/v1'
    : 'http://localhost:8000/v1';
  return isProduction() ? prodUrl : devUrl;
};
