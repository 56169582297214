import { jwtDecode } from 'jwt-decode';

interface JWTToken {
  sub: string;
  name: string;
  is_coach: boolean;
  list_of_users?: { [uuid: string]: string };
  list_of_coaches?: { [uuid: string]: string };
}

function decodeToken(): JWTToken | null {
  const token = sessionStorage.getItem('jwt');
  if (!token) return null;

  try {
    return jwtDecode<JWTToken>(token);
  } catch (error) {
    return null;
  }
}

export function getName(): string {
  const decoded = decodeToken();
  return decoded?.name ?? '';
}

export function isCoach(): boolean {
  const decoded = decodeToken();
  return decoded?.is_coach ?? false;
}

export function getSuS(): { [uuid: string]: string } {
  const decoded = decodeToken();
  return decoded?.list_of_users ?? {};
}
