import { Alert, Box, Button, Snackbar, TextField } from '@mui/material';
import { Formik } from 'formik';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import { login } from '../../api/auth';
import { useState } from 'react';

const Login = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  const handleLogin = async (values) => {
    try {
      await login(values.username, values.password);
      window.location.href = '/';
    } catch (error) {
      setMessage('Benutzername oder Passwort ist falsch.');
      setSeverity('error');
      setOpen(true);
    }
  };

  return (
    <Box m="auto" sx={{ p: '15px' }}>
      <Box display="flex" justifyContent="center" alignItems="center" mb="5vh">
        <img alt="Logo" height="128px" src="logo512.png" style={{ borderRadius: '50%' }} />
      </Box>
      <Header title="Anmelden" subtitle="Melden Sie sich mit Ihrem Konto an." />

      <Formik onSubmit={handleLogin} initialValues={initialValues}>
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                autoComplete="username"
                label="Benutzername"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                name="username"
                error={!!touched.username && !!errors.username}
                helperText={touched.username && errors.username}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                autoComplete="current-password"
                label="Passwort"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: 'span 2' }}
              />
            </Box>
            <Box display="flex" justifyContent="start" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Anmelden
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const initialValues = {
  username: '',
  password: '',
};

export default Login;
